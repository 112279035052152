import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        currencyPairsSelect: [],
    }),
    mutations: {
        setCurrencyPairsSelect(state, items) {
            state.currencyPairsSelect = items;
        },
    },
    actions: {
        getCurrencyPairsSelect({rootState, commit}) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

            return new Promise((resolve, reject) => {
                axios.get(`/${rootState.auth.path}/currency-pairs/select`)
                    .then(response => {
                        commit('setCurrencyPairsSelect', response.data);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
    },
};
