import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        futuresWallet: [],
    }),
    mutations: {
        setFuturesWallet(state, items) {
            state.futuresWallet = items
        },
    },
    actions: {
        saveToken({rootState, commit}, data) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.post(`/${rootState.auth.path}/users/binance`, data)
                    .then(response => {
                        commit('auth/setIsConnectBinance', true, { root: true })
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors.response.data)
                    })
            })
        },
        getFuturesWallet({rootState, commit}) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.get(`/${rootState.auth.path}/binance/futures-wallet`)
                    .then(response => {
                        commit('setFuturesWallet', response.data)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors.response.data)
                    })
            })
        },
    },
}
