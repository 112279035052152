import axiosIns from '@axios'

export default {
	namespaced: true,
	state: () => ({
		token: localStorage.getItem('access_token') || null,
		role: localStorage.getItem('role') || null,
		userName: localStorage.getItem('userName') || null,
		path: localStorage.getItem('path') || null,
		verify: localStorage.getItem('verify') || false,
		subscriptionPrice: localStorage.getItem('subscriptionPrice') || false,
		userData: localStorage.getItem('userData') || null,
		isConnectBinance: !!localStorage.getItem('isConnectBinance'),
	}),
	getters: {
		loggedIn(state) {
			return state.token !== null
		},
	},
	mutations: {
		retrieveToken(state, token) {
			localStorage.setItem('access_token', token);
			state.token = token;
		},
		setRole(state, role) {
			localStorage.setItem('role', role);
			state.role = role;
		},
		setPath(state, role) {
			localStorage.setItem('path', role);
			state.path = role;
		},
		setVerify(state, verify) {
			localStorage.setItem('verify', verify);
			state.verify = verify;
		},
		setSubscriptionPrice(state, item) {
			localStorage.setItem('subscriptionPrice', item)
			state.subscriptionPrice = item;
		},
		setUserData(state, item) {
			localStorage.setItem('userData', item)
			state.userData = item;
		},
		setIsConnectBinance(state, item) {
			localStorage.setItem('isConnectBinance', item)
			state.isConnectBinance = item
		},
		destroyRole(state) {
			localStorage.removeItem('role')
			state.role = null;
		},
		destroyPath(state) {
			localStorage.removeItem('path')
			state.path = null;
		},
		destroyToken(state) {
			localStorage.removeItem('access_token')
			state.token = null;
		},
		destroyVerify(state) {
			localStorage.removeItem('verify');
			state.verify = null;
		},
		destroySubscriptionPrice(state) {
			localStorage.removeItem('subscriptionPrice')
			state.subscriptionPrice = null
		},
		destroyIsConnectBinance(state) {
			localStorage.removeItem('isConnectBinance')
			state.isConnectBinance = null
		},
	},
	actions: {
		login({ commit }, credentials) {
			return new Promise((resolve, reject) => {
				commit('app/setOverlay', true, { root: true })

				axiosIns.post('/login', credentials)
					.then(response => {
						const { role } = response.data
						const userData = response.data

						userData.id = 1
						userData.fullName = 'User'
						userData.ability = [
							{
								action: 'manage',
								subject: 'all',
							},
						]
						userData.extras = {
							eCommerceCartItemsCount: 5,
						}

						commit('setUserData', JSON.stringify(userData))
						commit('retrieveToken', response.data.token)
						commit('setRole', role)
						commit('setPath', role)
						commit('setVerify', response.data.verify)
						commit('setSubscriptionPrice', response.data.subscription_price)
						commit('setIsConnectBinance', response.data.binance_connect)

						resolve(response)
					})
					.catch(errors => {
						reject(errors)
					})
					.finally(() => commit('app/setOverlay', false, { root: true }))
			})
		},
		registration({commit}, data) {
			return new Promise((resolve, reject) => {
				commit('app/setOverlay', true, { root: true })

				axiosIns.post('/registration', data)
					.then(response => {
						resolve(response)
					})
					.catch(errors => {
						reject(errors)
					})
					.finally(() => commit('app/setOverlay', false, { root: true }))
			});
		},
		destroyToken({state, commit, getters}) {
			axiosIns.defaults.headers.common.Authorization = `Bearer ${state.token}`

			if (getters.loggedIn) {
				return new Promise((resolve, reject) => {
					axiosIns.post('/logout')
						.then(response => {
							resolve(response)
						})
						.catch(error => {
							reject(error)
						})
						.finally(() => {
							commit('destroyToken')
							commit('destroyRole')
							commit('destroyPath')
							commit('destroyVerify')
							commit('destroySubscriptionPrice')
							commit('destroyIsConnectBinance')
						})
				})
			}
		},
		sendVerifyMail({dispatch, state}, data) {
			axiosIns.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;

			return new Promise((resolve, reject) => {
				axiosIns.get('/auth/verify/resend', data)
					.then(response => {
						resolve(response);
					})
					.catch(errors => {
						reject(errors);
					});
			});
		},
		verify({dispatch}, data) {
			const url = `/auth/verify/${data.id}/${data.hash}?expires=${data.expires}&signature=${data.signature}`;

			return new Promise((resolve, reject) => {
				axiosIns.get(url, data)
					.then(response => {
						resolve(response);
					})
					.catch(errors => {
						reject(errors);
					});
			});
		},
		forgotPassword({dispatch, commit}, data) {
			return new Promise((resolve, reject) => {
				axiosIns.post('/forgot-password', data)
					.then(response => {
						resolve(response);
					})
					.catch(errors => {
						reject(errors);
					})
			});
		},
		resetPassword({dispatch, commit}, data) {
			return new Promise((resolve, reject) => {
				axiosIns.post('/reset-password', data)
					.then(response => {
						resolve(response);
					})
					.catch(errors => {
						reject(errors);
					})
			});
		},
		resetPasswordClient({ dispatch, rootState, commit }, data) {
			axiosIns.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

			return new Promise((resolve, reject) => {
				axiosIns.post(`${rootState.auth.path}/reset-password-client`, data)
					.then(response => {
						resolve(response)
					})
					.catch(errors => {
						reject(errors.response.data)
					})
			})
		},
	},
};
