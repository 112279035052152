import axios from '@axios'

export default {
	namespaced: true,
	state: () => ({
		subscriptions: [],
		current: 0,
		active: {
			message: '',
			status: false,
		},
		paymentStatus: false,
	}),
	mutations: {
		setSubscriptions(state, items) {
			state.subscriptions = items;
		},
		setActive(state, items) {
			state.active = items;
		},
		setCurrent(state, item) {
			state.current = item;
		},
		setPaymentStatusPopup(state, status) {
			state.paymentStatus = status;
		},
	},
	actions: {
		getSubscriptions({commit, rootState}) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

			return new Promise((resolve, reject) => {
				axios.get('/admin/subscriptions/select')
					.then(response => {
						commit('setSubscriptions', response.data);
						resolve(response);
					})
					.catch(errors => {
						reject(errors);
					});
			});
		},
		getSubscriptionActive({commit, rootState}) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

			return new Promise((resolve, reject) => {
				axios.get('/client/subscriptions/active')
					.then(response => {
						commit('setActive', response.data);
						resolve(response);
					})
					.catch(errors => {
						reject(errors);
					});
			});
		},
		activateSubscription({commit, rootState, dispatch}) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;
			commit('preloader/setIsActive', true, {root: true});

			return new Promise((resolve, reject) => {
				axios.post('/client/subscriptions/activate')
					.then(response => {
						dispatch('getCurrent');
						resolve(response);
					})
					.finally(() => {
						commit('preloader/setIsActive', false, {root: true});
					})
			});
		},
		getCurrent({commit, rootState}) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

			return new Promise((resolve, reject) => {
				axios.get('/client/subscriptions/current')
					.then(response => {
						commit('setCurrent', response.data);
						resolve(response);
					})
					.catch(errors => {
						reject(errors);
					});
			});
		},
	},
}
