import auth from './middleware/auth'
import admin from './middleware/admin'
import guest from './middleware/guest'
import client from './middleware/client'
import trader from './middleware/trader'

export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      // middleware: [
      //   guest,
      // ],
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
      middleware: [
        guest,
      ],
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/Main.vue'),
    meta: {
      middleware: [
        auth,
        admin,
      ],
    },
    children: [
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: () => import('@/views/admin/dashboard/Ecommerce.vue'),
      },
      {
        path: 'signals',
        name: 'admin-signals',
        component: () => import('@/views/admin/signals/Signals.vue'),
        meta: {
          contentRenderer: 'sidebar-left-detached',
          contentClass: 'ecommerce-application',
          pageTitle: 'Сигналы',
        },
      },
      {
        path: 'orders',
        name: 'admin-orders',
        component: () => import('@/views/admin/orders/Orders.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Сделки',
        },
      },
      {
        path: 'users',
        name: 'admin-users',
        component: () => import('@/views/admin/users/Users.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Пользователи',
        },
      },
      {
        path: 'traders',
        name: 'admin-traders',
        component: () => import('@/views/admin/traders/Traders.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Трейдеры',
        },
      },
      {
        path: 'traders/:id',
        name: 'admin-trader-single',
        component: () => import('@/views/admin/traders/trader-view/UsersView.vue'),
      },
      {
        path: 'binance-logs',
        name: 'admin-binance-logs',
        component: () => import('@/views/admin/binance-logs/Logs.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Binance logs',
        },
      },
      {
        path: 'settings',
        name: 'admin-setting',
        component: () => import('@/views/admin/account-setting/AccountSetting.vue'),
        meta: {
          pageTitle: 'Настройки',
        },
      },
    ],
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('@/views/client/Main.vue'),
    meta: {
      middleware: [
        auth,
        client,
      ],
    },
    children: [
      {
        path: 'dashboard',
        name: 'client-dashboard',
        component: () => import('@/views/client/dashboard/Ecommerce.vue'),
      },
      {
        path: 'signals',
        name: 'client-signals',
        component: () => import('@/views/client/signals/Signals.vue'),
        meta: {
          contentRenderer: 'sidebar-left-detached',
          contentClass: 'ecommerce-application',
          pageTitle: 'Сигналы',
        },
      },
      {
        path: 'orders',
        name: 'client-orders',
        component: () => import('@/views/client/orders/Orders.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Сделки',
        },
      },
      {
        path: 'traders',
        name: 'client-traders',
        component: () => import('@/views/client/traders/Traders.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Трейдеры',
        },
      },
      {
        path: 'traders/:id',
        name: 'client-trader-single',
        component: () => import('@/views/client/traders/trader-view/UsersView.vue'),
      },
      {
        path: 'pricing',
        name: 'client-pricing',
        component: () => import('@/views/client/pricing/Pricing.vue'),
      },
      {
        path: 'binance-logs',
        name: 'client-binance-logs',
        component: () => import('@/views/client/binance-logs/Logs.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Binance logs',
        },
      },
      {
        path: 'settings',
        name: 'client-setting',
        component: () => import('@/views/client/account-setting/AccountSetting.vue'),
        meta: {
          pageTitle: 'Настройки',
        },
      },
    ],
  },
  {
    path: '/trader',
    name: 'trader',
    component: () => import('@/views/trader/Main.vue'),
    meta: {
      middleware: [
        auth,
        trader,
      ],
    },
    children: [
      {
        path: 'dashboard',
        name: 'trader-dashboard',
        component: () => import('@/views/trader/dashboard/Ecommerce.vue'),
      },
      {
        path: 'signals',
        name: 'trader-signals',
        component: () => import('@/views/trader/signals/Signals.vue'),
        meta: {
          contentRenderer: 'sidebar-left-detached',
          contentClass: 'ecommerce-application',
          pageTitle: 'Сигналы',
        },
      },
      {
        path: 'orders',
        name: 'trader-orders',
        component: () => import('@/views/trader/orders/Orders.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Сделки',
        },
      },
      {
        path: 'binance-logs',
        name: 'trader-binance-logs',
        component: () => import('@/views/trader/binance-logs/Logs.vue'),
        meta: {
          contentClass: 'ecommerce-application',
          pageTitle: 'Binance logs',
        },
      },
      {
        path: 'settings',
        name: 'trader-setting',
        component: () => import('@/views/trader/account-setting/AccountSetting.vue'),
        meta: {
          pageTitle: 'Настройки',
        },
      },
    ],
  },
]
