import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL_API,
  // timeout: 1000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// axiosIns.interceptors.response.use(function (response) {
//     // if(store.state.auth.path == null) {
//     //     store.commit('auth/destroyToken');
//     //     store.commit('auth/destroyRole');
//     //     store.commit('auth/destroyPath');
//     //     if(useRoute().name !== 'login'){
//     //         useRoute().push({ name: 'login'});
//     //     }
//     // }
//     return response
// }, function (error) {
//     if ((error.response.status === 401 || store.state.auth.path === null) && route !== undefined) {
//         store.commit('auth/destroyToken');
//         store.commit('auth/destroyRole');
//         store.commit('auth/destroyPath');
//         if (route.name !== 'login') {
//             router.push({name: 'login'});
//         }
//     }
//     return Promise.reject(error);
// });

Vue.prototype.$http = axiosIns

export default axiosIns
