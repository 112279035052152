import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        trader: {},
        traders: [],
    }),
    mutations: {
        setTrader(state, data) {
            state.trader = data
        },
        setTraders(state, items) {
            state.traders = items
        },
    },
    actions: {
        getTrader({ rootState, commit }, id) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.get(`/${rootState.auth.path}/traders/${id}`)
                    .then(response => {
                        commit('setTrader', response.data)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        getTraders({ rootState, commit }) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.get(`/${rootState.auth.path}/traders`)
                    .then(response => {
                        commit('setTraders', response.data)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        attachTrader({ rootState }, traderId) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.post(`/${rootState.auth.path}/traders/attach`, {
                    trader_id: traderId,
                })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        saveSettings({ rootState, commit }, data) {
            commit('app/setOverlay', true, { root: true })
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.post(`/${rootState.auth.path}/traders/settings`, data)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
                    .finally(() => commit('app/setOverlay', false, { root: true }))
            })
        },
        unsubscribe({ rootState, commit }, data) {
            commit('app/setOverlay', true, { root: true })
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.post(`/${rootState.auth.path}/traders/unsubscribe`, data)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
                    .finally(() => commit('app/setOverlay', false, { root: true }))
            })
        },
    },
};
