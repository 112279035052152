import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        signalStatusSelect: [],
    }),
    mutations: {
        setSignalStatusSelect(state, items) {
            state.signalStatusSelect = items;
        },
    },
    actions: {
        getSignalStatusSelect({rootState, commit}) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.get(`/${rootState.auth.path}/signal-statuses/select`)
                    .then(response => {
                        commit('setSignalStatusSelect', response.data.data)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    });
            });
        },
    },
};
