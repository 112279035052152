import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        user: {},
        users: [],
        countUsers: 0,
        popupCreate: false,
        popupEdit: false,
        userEdited: {
            role_id: '',
            subscription_id: '',
        },
        isWatchPopup: localStorage.getItem('isWatchPopup') || false,
        countNewClients: 0,
        countClients: 0,
        usersData: {
            count_clients: 0,
            count_new_clients: 0,
            count_free: 0,
            count_beginners: 0,
            count_pro: 0,
        },
        traders: [],
    }),
    mutations: {
        setUser(state, item) {
            state.user = item
        },
        setUsers(state, items) {
            state.users = items
        },
        addItem(state, item) {
            state.users.unshift(item)
        },
        setPopupCreate(state, status) {
            state.popupCreate = status
        },
        setPopupEdit(state, status) {
            state.popupEdit = status
        },
        deleteUser(state, id) {
            let index = state.users.findIndex(item => item.id === id);
            state.users.splice(index, 1);
        },
        setUserEdited(state, item) {
            state.userEdited = item;
        },
        setRole(state, item) {
            state.userEdited.role_id = item;
        },
        setSubscription(state, item) {
            state.userEdited.subscription_id = item;
        },
        updateUser(state, item) {
            let index = state.users.findIndex(userItem => userItem.id === item.id);
            Object.assign(state.users[index], item);
        },
        setIsWatchPopup(state) {
            localStorage.setItem('isWatchPopup', 'true');
            state.isWatchPopup = true;
        },
        setUsersData(state, items) {
            state.usersData = items;
        },
        setCountUsers(state, items) {
            state.countUsers = items;
        },
        setTraders(state, items) {
            state.traders = items
        },
    },
    actions: {
        getUser({ rootState, commit }) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            const url = '/all/users/details'

            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        commit('setUser', response.data)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        getUsers({rootState, state, commit}, page) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            let url = `/admin/users/?page=${page}`;

            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        commit('setUsers', response.data.data.data);
                        commit('setCountUsers', response.data.data.total);
                        commit('setUsersData', response.data.meta);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
        createUser({ rootState, commit }, data) {
            commit('app/setOverlay', true, { root: true })

            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.post('/admin/users', data)
                    .then(response => {
                        commit('addItem', response.data.data)
                        commit('setPopupCreate', false)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
                    .finally(() => commit('app/setOverlay', false, { root: true }))
            })
        },
        updateUser({rootState, commit, state}, data) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.patch(`/admin/users/${state.userEdited.id}`, data)
                    .then(response => {
                        commit('setPopupEdit', false);
                        commit('updateUser', response.data.data);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
        deleteUser({rootState, commit}, id) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.delete(`/admin/users/${id}`)
                    .then(response => {
                        commit('deleteUser', id)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        update({ rootState, commit, state}, image) {
            commit('app/setOverlay', true, { root: true })

            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            const formData = new FormData()

            formData.append('image', image)
            formData.append('email', state.user.email)
            formData.append('telegram', state.user.telegram)
            formData.append('name', state.user.name)

            return new Promise((resolve, reject) => {
                axios.post('/all/users/details', formData)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
                    .finally(() => commit('app/setOverlay', false, { root: true }))
            })
        },
    },
};
