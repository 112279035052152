import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        roles: [],
    }),
    mutations: {
        setRoles(state, items) {
            state.roles = items
        },
    },
    actions: {
        getRoles({ commit, rootState }) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.get('/admin/roles/select')
                    .then(response => {
                        commit('setRoles', response.data.data)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
    },
}
