import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import auth from './main/auth'
import signal from './main/signal'
import signalStatus from './main/signalStatus'
import role from './main/role'
import user from './main/user'
import trader from './main/trader'
import order from './main/order'
import userTraderOrder from './main/userTraderOrder'
import payments from './main/payments'
import subscription from './main/subscription'
import binance from './main/binance'
import currencyPair from './main/currencyPair'
import binanceLogs from './main/binanceLogs'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    signal,
    signalStatus,
    role,
    user,
    trader,
    order,
    userTraderOrder,
    payments,
    binance,
    subscription,
    currencyPair,
    binanceLogs,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
