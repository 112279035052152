import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        signals: [],
        statistics: [],
        countSignals: 0,
        signalEdited: {
            currency_pair_id: '',
            position_id: '',
            status_id: '',
        },
        filersStatuses: [],
    }),
    mutations: {
        setSignals(state, items) {
            state.signals = items;
        },
        setCountSignals(state, item) {
            state.countSignals = item;
        },
        addItem(state, item) {
            state.signals.unshift(item);
        },
        deleteSignal(state, id) {
            let index = state.signals.findIndex(item => item.id === id);
            state.signals.splice(index, 1);
        },
        setSignalEdited(state, item) {
            state.signalEdited = item;
        },
        setCurrencyPair(state, item) {
            state.signalEdited.currency_pair_id = item;
        },
        setStatus(state, item) {
            state.signalEdited.status_id = item;
        },
        setStatistics(state, items) {
            state.statistics = items;
        },
        setFilersStatuses(state, items) {
            state.filersStatuses = items;
        },
        updateSignal(state, item) {
            let index = state.signals.findIndex(signalItem => signalItem.id === item.id);
            Object.assign(state.signals[index], item);
        },
        setPricesFromWS(state, message) {

            const data = JSON.parse(message);

            const currency = data.stream.split('@')[0];

            state.signals.forEach((value, index) => {

                if (currency.toUpperCase() === value.currency_pair_split) {
                    if (currency.c > 1) {
                        state.signals[index].price = (Math.round(data.data.c * 100) / 100)
                    } else {
                        state.signals[index].price = data.data.c * 1;
                    }

                    if (currency.q > 1) {
                        state.signals[index].hour_24 = (Math.round(data.data.q * 100) / 100).toLocaleString()
                    } else {
                        state.signals[index].hour_24 = data.data.q * 1;
                    }
                }
            });
        },
    },
    actions: {
        getSignals({ rootState, state, commit }, page = 1) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            let url = `/${rootState.auth.path}/signals/?page=${page}`

            if(state.filersStatuses.length > 0) {
                url += `&statuses=${state.filersStatuses}`
            }

            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        commit('setSignals', response.data.data.data)
                        commit('setCountSignals', response.data.data.total)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        createSignal({rootState, commit}, data) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

            return new Promise((resolve, reject) => {
                axios.post(`/${rootState.auth.path}/signals`, data)
                    .then(response => {
                        commit('addItem', response.data.data);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    })
            });
        },
        editSignal({rootState, commit, state}, data) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

            return new Promise((resolve, reject) => {
                axios.patch(`/${rootState.auth.path}/signals/${data.id}`, data)
                    .then(response => {
                        commit('updateSignal', response.data.data);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    })
            });
        },
        deleteSignal({rootState, commit}, id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

            return new Promise((resolve, reject) => {
                axios.delete(`/${rootState.auth.path}/signals/${id}`)
                    .then(response => {
                        commit('deleteSignal', id);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
        getStatistics({rootState, commit}) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

            let url = `/${rootState.auth.path}/signals/statistics`;

            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        commit('setStatistics', response.data.data);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
    },
};
