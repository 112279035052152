import axios from '@axios'

export default {
    state: () => ({
        orders: [],
        newOrder: {
            signal_id: '',
            price: 0,
            adjustLeverage: 1,
            market_entry: '',
        },
        countOrders: 0,
    }),
    mutations: {
        setOrders(state, items) {
            state.orders = items;
        },
        setCountOrders(state, item) {
            state.countOrders = item;
        },
        setNewOrder(state, item) {
            state.newOrder = item;
        },
        updateOrder(state, item) {
            let index = state.orders.findIndex(orderItem => orderItem.id === item.id);
            Object.assign(state.orders[index], item);
        },
        setPNLFromWS(state, message) {

            const data = JSON.parse(message)

            const currency = data.stream.split('@')[0]

            const index = state.orders.findIndex(item => item.currency_pair_split === currency.toUpperCase());

            state.orders[index].current_price = (Math.round(data.data.p * 100) / 100)
            if(state.orders[index].position_id === 2) {
                state.orders[index].pnl = ((data.data.p - state.orders[index].price) * state.orders[index].quantity).toFixed(2);
            } else {
                state.orders[index].pnl = ((state.orders[index].price - data.data.p) * state.orders[index].quantity).toFixed(2);
            }

            if(state.orders[index].pnl >= 0) {
                state.orders[index].pnl_color = 'green';
            } else {
                state.orders[index].pnl_color = 'red';
            }
        },
    },
    actions: {
        getOrders({rootState, state, commit}, page) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

            let url = `/${rootState.auth.path}/orders/?page=${page}`;

            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        commit('setOrders', response.data.data.data);
                        commit('setCountOrders', response.data.data.total);
                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
        getActiveOrders({rootState, state, commit}) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            const url = `/${rootState.auth.path}/orders/active-orders`

            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        commit('setOrders', response.data.data)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
        createOrder({rootState, commit, state, dispatch}, data) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                commit('app/setOverlay', true, { root: true })

                axios.post(`/${rootState.auth.path}/orders`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
                    .finally(() => commit('app/setOverlay', false, { root: true }))
            });
        },
        cancelOrder({rootState, commit, state, dispatch}, data) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token;

            return new Promise((resolve, reject) => {
                axios.post(`/${rootState.auth.path}/orders/cancel`, data)
                    .then(response => {
                        commit('updateOrder', response.data.data);

                        resolve(response);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
    },
    namespaced: true
};
