import axios from '@axios'

export default {
  state: () => ({
    orders: [],
    countOrders: 0,
  }),
    mutations: {
        setOrders(state, items) {
            state.orders = items
        },
        setCountOrders(state, item) {
            state.countOrders = item
        },
    },
    actions: {
        getOrders({rootState, state, commit}, data) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            const url = `/${rootState.auth.path}/user-trader-orders/?page=${data.page}&trader_id=${data.trader_id}`

            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        commit('setOrders', response.data.data)
                        commit('setCountOrders', response.data.total)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            })
        },
    },
    namespaced: true,
}
