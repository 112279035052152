import axios from '@axios'

export default {
    namespaced: true,
    state: () => ({
        binanceLogs: [],
        countBinanceLogs: 0,
    }),
    mutations: {
        setBinanceLogs(state, items) {
            state.binanceLogs = items
        },
        setCountBinanceLogs(state, items) {
            state.countBinanceLogs = items
        },
    },
    actions: {
        getItems({rootState, commit}, page) {
            axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

            return new Promise((resolve, reject) => {
                axios.get(`/all/binance-logs?page=${page}`)
                    .then(response => {
                        commit('setBinanceLogs', response.data.data)
                        commit('setCountBinanceLogs', response.data.total)
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors.response.data)
                    })
            })
        },
    },
}
