import axios from '@axios'

export default {
  namespaced: true,
  actions: {
	getLinkPayments({ rootState }, subscriptionId) {
      axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.token}`

      return new Promise((resolve, reject) => {
        axios.get(`/client/payments/get-link?subscription_id=${subscriptionId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(errors => {
            reject(errors)
          })
      })
    },
  },
}
