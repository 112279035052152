import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouteMiddleware from 'vue-route-middleware'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import main from './routes/main'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
// import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...main,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()
//
//   console.log(isLoggedIn)
//
//   if (!canNavigate(to)) {
//     console.log(1)
//
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })
//
//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }
//
//   // Redirect if logged in
//   console.log(to.meta.redirectIfLoggedIn)
//
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     console.log(2)
//
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }
//   console.log(3)
//   return next()
// })

router.beforeEach(VueRouteMiddleware())

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
